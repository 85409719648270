/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

 .pageHeader {
    border-radius: 10px;
    padding-bottom: 0;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.positioning {
    margin-top: -17px;
    padding-bottom: 17px;
}

.commonStyle {
    width: 250px;
    text-align: right;
}

.sidePadding {
    padding: 5px 30px 0px 30px;
}

.title{
    margin-left: 30px;
    color: rgba(0, 0, 0, 0.70) !important;
}

.sectionContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #f7f7f7;
}

.column {
    width: 48%;
    padding: 10px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}

.column p {
    margin: 5px 0;
    font-weight: bold;
}

.link {
    color: #075b9e;
    textDecoration: 'underline';
}

.tableContainer {
    border: 1px solid rgba(187, 187, 187, 0.2);
    border-radius: 7px;
}

.customTable tbody > tr > td {
    height: 10px;
    padding: 10px 10px;
}

@media screen and (max-width: 767px) {
    .customTable tbody > tr > td {
        height: 5px;
        padding: 4px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .customTable tbody > tr > td {
        height: 8px;
        padding: 6px;
    }
}

@media screen and (min-width: 1024px) {
    .customTable tbody > tr > td {
        height: 12px;
        padding: 10px;
    }
}

