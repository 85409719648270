/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.content{
    border-radius: 10px;
    background: #ffffff;
    min-height: 70vh;
}

.spinner {
    margin-top: 300px;
    text-align: center;
    margin-left: 45%;
}

.container{
    padding: 25px 75px;
}

.table {
    padding:10px;
}

.tableContainer {
    padding: 10px 10px;
    border: 1px solid rgba(187, 187, 187, 0.2);
    border-radius: 7px;
    margin-top: -10px;
}

.searchCollapse {
    background: #fafafa;
    border-radius: 10px;
    border: 0px;
    overflow: hidden;
    margin-bottom: 20px;
}