/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.description {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: -3px;
    margin-top: -15px;
}

.pageHeader {
    border-radius: 10px;
    padding-top: 16px;
    box-shadow: 0 5px 8px #f0f1f2;
    z-index: 2;
}

.icon{
    color: rgba(0, 0, 0, 0.65);
    margin-right: 6px;
    padding-left: 46px;
}

.customEmpty{
    margin: 0;
}

.responseRow {
    background-color: #fffbe6;
    border: #ffe58f;
    padding: 8px 15px;
    line-height: 1.5715;
}

.flexContainer {
    display: flex;
    flex-direction: column;
}

.property {
    flex: 1;
    margin-bottom: 8px;
}

.table {
    padding: 10px 50px;
    }

.searchCollapse {
    background: #fafafa;
    border-radius: 10px;
    border: 0px;
    overflow: hidden;
    width: 100%;
        margin-right: 20px;
        margin-bottom: 5px;
}


.rightAlign{
    text-align: right; 
}

.flexbox{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}