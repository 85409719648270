/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.App {
  padding: 20px;
}

.ant-layout-header {
    padding: 0;
    height: auto;
    box-shadow: 0 2px 8px #f0f1f2;
    border-radius: 10px;
        margin-top: 0px;
}

.ant-layout-footer {
    padding: 12px 50px 30px 50px !important;
}
.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    margin-top: 24px;
}

.ant-input-affix-wrapper .ant-input{
    min-height: 0;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
    font-weight: 300;
}

img.twemoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.ant-menu-dark .ant-menu-item-selected {
    color: black !important;
}

#rc-tabs-0-panel-5 .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0 !important;
}
.ant-table-tbody>tr>td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
    padding-top: 0px;
        padding-bottom: 0px;
    }

    .ant-table-tbody>tr>td {
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.3s;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 12px;
    }

    .ant-table-thead>tr>th,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 16px;
        padding-right: 16px;
        overflow-wrap: break-word;
        font-size: 12px;
    }

    .ant-menu-vertical.ant-menu-sub .ant-menu-item {
        width: auto;
}
.ant-page-header {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 16px !important;
}
.ant-btn-primary {
    box-shadow: none
}
td.ant-table-cell {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
th.ant-table-cell {
    padding-bottom: 11px !important;
    padding-top: 11px !important;
}
