/*
 * Copyright (C) 2018 - 2024, Entgra (pvt) Ltd. (http://entgra.io) All Rights Reserved.
 *
 * Entgra (pvt) Ltd. licenses this file to you under the Apache License,
 * Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.appCard {
    border-radius: 10px;
    margin: 15px 0 15px 5px;
    width: 97%;
}

.avatar {
    vertical-align: middle;
    border-radius: 10px;
    font-size: 20px;
}

.cardBody{
    text-align: center;
    align-content: center;
    align-items: center;
}

.nameText {
    font-size: 12px;
    font-weight: bold;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 32px
}

.apiTableContainer {
    margin-top: 15px;
}