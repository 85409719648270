/*
 * Copyright (C) 2018 - 2023. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.dateRangerPicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 4px 20px;
    color: #7b8087;
    font-size: 14px;
    cursor: pointer;
    overflow: auto;
        height: 32px;
    margin-right: auto;
}

.dateRangerPicker i {
    margin-right: 5px;
}

.dateRangerPickerDropdown {
    width: 500px;
    height: auto;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 15px 5px;
    position: absolute;
    top: 50px;
    right: 0;
    left: unset;
    z-index: 1001;
}

.dateRangerPickerDropdown .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
}

.dateRangerPickerDropdown .btn-primary {
    margin-top: 15px;
}

.dateRangerPickerDropdown label {
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 5px;
}

.absoluteTimeRange {
    width: 50%;
    padding: 0 10px 5px 5px;
}

.absoluteTimeRange label {
    font-weight: 400;
    color: #666f77;
}

.relativeTimeRanges {
    width: 50%;
    padding: 0 10px;
    border-left: 1px solid #ddd;
}

.relativeTimeRanges label {
    font-weight: 400;
    color: #666f77;
}

.dateRangerPickerColumns {
    display: flex;
    justify-content: space-between;
}

.leftLine {
    border-left: 1px solid #ddd;
    padding-left: 20px;
}

.dateRangerPickerDropdown ul {
    list-style: none;
    padding: 0;
}

.dateRangerPickerDropdown li {
    margin: 5px 0;
}

.dateRangerOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.16);
    z-index: 0;
}

.dropdownStyle {
    width: 400px;
    height: auto;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 15px 5px;
    position: absolute;
    top: 50px;
    left: 0;
    right: unset;
}

.datePicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 10px 15px;
    color: #7b8087;
    font-size: 14px;
    cursor: pointer;
}

.datePicker span {
    pointer-events: none;
}

.dateRanger {
    width: 160px;
    position: relative;
}

.scrollableList {
    overflow: auto;
    height: 300px;
    width: auto;
}
