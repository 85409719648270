/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */

.content {
    padding-top: 32px;
    padding-bottom: 32px;
}

.title {
    padding-bottom: 12px;
}

.viewAllButton {
    text-align: center;
}

.formattedItems :global .ant-descriptions-view {
    overflow-x: auto;
}

.deviceDetailsModal :global .ant-modal-body {
    max-height: 60vh;
    overflow-y: auto;
}
