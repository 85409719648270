/*
 * Copyright (C) 2018 - 2024. Entgra (Pvt) Ltd, https://entgra.io
 * All Rights Reserved.
 *
 * Unauthorized copying/redistribution of this file, via any medium
 * is strictly prohibited.
 * Proprietary and confidential.
 *
 * Licensed under the Entgra Commercial License,
 * Version 1.0 (the "License");
 * you may not use this file except in compliance with the License.
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 *
 * You may obtain a copy of the License at
 * https://entgra.io/licenses/entgra-commercial/1.0
 */
.react-flow {
    --bg-color: rgb(255, 255, 255);
    --text-color: rgb(17, 17, 17);
    --node-border-radius: 10px;
    --node-box-shadow: 10px 0 15px rgba(42, 138, 246, 0.3), -10px 0 15px rgba(42, 138, 246, 0.3);
    background-color: var(--bg-color);
    color: var(--text-color);
}

.react-flow__node-turbo {
    border-radius: var(--node-border-radius);
    display: flex;
    min-height: 55px;
    min-width: 220px;
    font-family: Poppins;
    font-weight: 500;
    letter-spacing: -0.2px;
    box-shadow: var(--node-box-shadow);
    padding: '5px';
    margin: '5px';
}

.react-flow__node-turbo .wrapper {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    position: relative;
    border-radius: var(--node-border-radius);
    flex-grow: 1;
    font-family: Poppins;
    min-width: 220px;
}

.react-flow__node-turbo .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    min-width: 180px;
}

.react-flow__node-turbo .timerow {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    min-width: 180px;
    color: #006666;
    margin-left: auto;
    font-size: 12px;
    flex-wrap: wrap;
}

.gradient:before {
    content: '';
    position: absolute;
    padding-bottom: calc(100% * 1.41421356237);
    width: calc(100% * 1.41421356237);
    background: conic-gradient(from -160deg at 50% 50%,
            #182B3A 0deg,
            #20A4F3 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
}

.react-flow__node-turbo.selected .wrapper.gradient:before {
    content: '';
    background: conic-gradient(from -160deg at 50% 50%,
            #182B3A 0deg,
            #20A4F3 360deg);
    animation: spinner 4s linear infinite;
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: -1;
}

@keyframes spinner {
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

.react-flow__node-turbo .inner {
    background: var(--bg-color);
    padding: 16px 16px 5px 16px;
    border-radius: var(--node-border-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    min-width: 220px;
}

.react-flow__node-turbo .icon {
    font-size: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.react-flow__node-turbo .body {
    display: flex;
}

.react-flow__node-turbo .title {
    font-size: 16px;
    line-height: 1;
    font-family: Poppins;
}

.react-flow__node-turbo .pseudoDevicetitle {
    font-size: 16px;
    line-height: 1;
    font-family: Poppins;
    color: #777;
}

.react-flow__node-turbo .description {
    font-size: 12px;
    color: #777;
}

.react-flow__node-turbo .profileData {
    font-size: 12px;
    color: #006666;
    margin-right: 10px;
}

.react-flow__node-turbo .floatRight {
    font-size: 12px;
    color: #006666;
    flex-shrink: 0;
    margin-left: auto;
    padding-left: min(10px, 2%);
}

.react-flow__node-turbo .cloud {
    border-radius: 100%;
    width: 30px;
    height: 20px;
    right: 0;
    position: absolute;
    top: 0;
    transform: translate(50%, -50%);
    display: flex;
    transform-origin: center center;
    /* padding: 2px; */
    overflow: hidden;
    box-shadow: var(--node-box-shadow);
    z-index: 1;
}

.react-flow__node-turbo .cloud div {
    background-color: var(--bg-color);
    flex-grow: 1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.react-flow__handle {
    opacity: 1;
}

.react-flow__handle.source {
    bottom: -10px;
}

.react-flow__handle.target {
    top: -10px;
}

.react-flow__node:focus {
    outline: none;
}

.react-flow__edge .react-flow__edge-path {
    stroke: url(#edge-gradient);
    stroke-width: 2;
    stroke-opacity: 0.75;
    animation: dash 5s linear;
    stroke-dasharray: 4 2;
}

.react-flow__controls button {
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid #000;
    border-bottom: none;
}

.react-flow__controls button:hover {
    background-color: rgb(224, 224, 231);
}

.react-flow__controls button:first-child {
    border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
    border-bottom: 1px solid #000;
    border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
    fill: var(--text-color);
}

.react-flow__attribution {
    background: rgba(200, 200, 200, 0.2);
}

.react-flow__attribution a {
    color: #95679e;
}

.context-menu {
    background: white;
    border-style: solid;
    box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
    position: absolute;
    z-index: 10;
}

.context-menu button {
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
}

.context-menu button:hover {
    background: #14acf3;
}

.download-btn {
    border: 1px solid #eee;
    background: #ebf8ff;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
}

.download-btn:hover {
    opacity: 0.9;
}

.react-flow__controls-summaryview {
    color: #14acf3 !important;
}

.viewport-panel {
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
  
    &-button {
      border: none;
      background: #fefefe;
      border-bottom: 1px solid #eee;
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      cursor: pointer;
      user-select: none;
      padding: 5px;
  
      &:hover {
        background: #f4f4f4;
      }
  
      svg {
        width: 100%;
        max-width: 12px;
        max-height: 12px;
      }
  
      &:disabled {
        pointer-events: none;
  
        svg {
          fill-opacity: 0.4;
        }
      }
    }

  }


  .viewport-panel button {
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid #000;
    border-radius: 5px 5px 5px 5px;
    cursor: pointer;
    margin-left: 5px;
}

.viewport-panel button:hover {
    background-color: rgb(224, 224, 231);
}



/* .viewport-panel button:last-child {
    border-bottom: 1px solid #000;
    border-radius: 0 0 5px 5px;
} */

.viewport-panel button path {
    fill: var(--text-color);
}

.viewport-panel-select {
    border: none;
    background: #fefefe;
    border-bottom: 1px solid #eee;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    user-select: none;
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid #000;
    border-radius: 5px 5px 5px 5px;
    

    &:hover {
        background: #f4f4f4;
    }

    /* svg {
        width: 100%;
        max-width: 12px;
        max-height: 12px;
    } */

    &:disabled {
        pointer-events: none;

        svg {
            fill-opacity: 0.4;
        }
    }
}


.viewport-panel-input {
    border: none;
    background: #fefefe;
    border-bottom: 1px solid #eee;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    user-select: none;
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid #000;
    border-radius: 5px 5px 5px 5px;
    

    &:hover {
        background: #f4f4f4;
    }

    /* svg {
        width: 100%;
        max-width: 12px;
        max-height: 12px;
    } */

    &:disabled {
        pointer-events: none;

        svg {
            fill-opacity: 0.4;
        }
    }
}

.viewport-panel-switch {
    height: 27px;
    margin-left: 0px;
  }
  
/* Add this CSS to your stylesheet */
button[disabled]:hover {
    background-color: inherit; /* Or any other style you want when hovering over a disabled button */
    cursor: not-allowed;
  }

  
  



  